<template>
	<v-card>
		<v-card-title>
			Anasayfa Banner Görseller
			<v-spacer></v-spacer>

			<new-banner-dialog
				v-model="newBannerDialog"
				:banner-data="currentBanner"
				:disabled="newBannerButtonIsDisabled"
				@submit-banner="handleBannerSubmission"
				v-if="true"
			/>
		</v-card-title>
		<v-card-text>
			<v-list-item dense class="px-0 v-i" v-if="banners.length === 0">
				<v-list-item-content class="justify-center text-center"> Henüz bir banner eklenmemiş. </v-list-item-content>
			</v-list-item>
			<draggable v-model="banners" draggable=".draggable-item" @change="onOrderChange">
				<v-list-item class="draggable-item" dense v-for="(banner, index) in banners" :key="`banner-${index}`">
					<v-list-item-content class="d-flex flex-row">
						<v-row align="center">
							<v-col cols="12" md="2" class="d-flex">
								<v-icon v-if="banner.cbnImage" left medium>fa-ellipsis-vertical</v-icon>

								<div class="d-flex flex-column align-center" style="gap: 10px; width: 100%">
									<v-img
										v-if="banner.cbnImage"
										contain
										width="80"
										aspect-ratio="4 / 1.2"
										:lazy-src="bannerImage('desktop', banner)"
										:src="bannerImage('desktop', banner)"
									></v-img>
									<v-img
										v-if="banner.cbnMobileImage"
										contain
										width="80"
										aspect-ratio="1 / 1"
										:lazy-src="bannerImage('mobile', banner)"
										:src="bannerImage('mobile', banner)"
									></v-img>
								</div>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnTitle"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Alt Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnSubTitle"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Link"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnLink"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="1">
								<v-text-field dense v-model="banner.cbnTextColor" hide-details outlined readonly>
									<template v-slot:append>
										<v-menu
											v-model="banner.colorMenu"
											top
											nudge-bottom="105"
											nudge-left="16"
											:close-on-content-click="false"
										>
											<template v-slot:activator="{ on }">
												<div class="elevation-1" :style="swatchStyle(banner)" v-on="on" />
											</template>
											<v-card>
												<v-card-text class="pa-0">
													<v-color-picker flat v-model="banner.cbnTextColor" />
												</v-card-text>
											</v-card>
										</v-menu>
									</template>
								</v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-row dense class="d-flex justify-center">
							<v-col>
								<v-btn
									outlined
									color="grey darken-4"
									depressed
									class="text-capitalize"
									@click="openNewBannerDialog(banner, index)"
									v-if="false"
								>
									<v-icon left x-small>fas fa-pencil</v-icon>
									düzenle
								</v-btn>
							</v-col>
							<v-col>
								<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="removeBanner(banner)">
									<v-icon left x-small>fas fa-trash</v-icon>
									sil
								</v-btn>
							</v-col>
						</v-row>
					</v-list-item-action>
				</v-list-item>
			</draggable>
		</v-card-text>
	</v-card>
</template>

<script>
import draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import validations from './_validations';
import NewBannerDialog from './newBannerDialog.vue';

export default {
	name: 'BannerList',
	components: {
		draggable,
		NewBannerDialog,
	},
	mixins: [validationMixin, validations],
	props: {
		cmsTheme: {
			type: Object,
			required: true,
		},
		checkFileExtFn: {
			type: Function,
			required: true,
		},
		getFileFn: {
			type: Function,
			required: true,
		},
		readAsDataFn: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			currentBanner: null,
			currentIndex: null,
			newBannerDialog: false,
		};
	},
	computed: {
		banners: {
			get() {
				return this.cmsTheme.banners;
			},
			set(newBanners) {
				this.cmsTheme.banners = newBanners;
			},
		},
		newBannerButtonIsDisabled() {
			return this.cmsTheme.banners.length >= 5;
		},
		maxOrder() {
			return this.cmsTheme.banners && this.cmsTheme.banners.length > 0
				? Math.max(...this.cmsTheme.banners.map((obj) => obj.cbnOrder + 1))
				: 1;
		},
	},
	methods: {
		handleBannerSubmission(imageData) {
			const maxOrder =
				this.cmsTheme.banners?.length > 0 ? Math.max(...this.cmsTheme.banners.map((obj) => obj.cbnOrder)) + 1 : 1;

			const updatedImageData = {
				...imageData,
				cbnOrder: maxOrder,
				cbnCmpId: this.cmsTheme.companyId,
			};

			this.cmsTheme.banners.push(updatedImageData);
		},
		openNewBannerDialog(banner, index) {
			this.currentBanner = banner;
			this.currentIndex = index;
			this.newBannerDialog = true;
		},
		onOrderChange() {
			const updatedBanners = this.cmsTheme.banners.map((banner, index) => ({
				...banner,
				cbnOrder: index + 1,
			}));

			this.cmsTheme.banners = updatedBanners;
		},
		bannerImage(type, banner) {
			return type === 'desktop' ? banner.cbnImage : banner.cbnMobileImage;
		},
		removeBanner(banner) {
			const index = this.cmsTheme.banners.findIndex((x) => x === banner);

			if (index !== -1) {
				this.cmsTheme.banners.splice(index, 1);
			}
		},
		swatchStyle(banner) {
			return {
				backgroundColor: banner.cbnTextColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: banner.colorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.draggable-item {
		cursor: move !important;
	}
}
</style>
