/* eslint-disable no-unused-expressions */
// import { requiredIf } from 'vuelidate/lib/validators';

const validations = {
	validations: {
		cmsTheme: {
			banners: {
				// $each: {
				// 	bannerBlob: {
				// 		// eslint-disable-next-line no-unused-vars
				// 		required: requiredIf((value, vm) => !value.cbnId && !value.bannerBlob),
				// 	},
				// },
			},
		},
	},
	methods: {
		// validation methods for this page
		bannerBlobErrors(index) {
			const errors = [];
			const { $dirty, requiredIf } = this.cmsTheme.banners[index].bannerBlob;
			if (!$dirty) return errors;
			!requiredIf && errors.push('Lütfen bir resim seçin');
			return errors;
		},
	},
};

export default validations;
