/* eslint-disable no-unused-expressions */
import { email } from 'vuelidate/lib/validators';

const themeValidationMixin = {
	validations: {
		cmsTheme: {
			email: {
				email,
			},
		},
	},
	methods: {
		emailErrors() {
			const errors = [];
			const { $dirty, email } = this.$v.cmsTheme.email;
			if (!$dirty) return errors;
			!email && errors.push('Hatalı e-mail adresi girdiniz.');
			return errors;
		},
	},
};

export default themeValidationMixin;
