<template>
	<v-card flat v-if="initialized">
		<v-card-title> Tema/Şablon Ayarları </v-card-title>
		<v-card-text>
			<div class="d-flex align-content-start mb-5">
				<v-avatar tile size="150" class="logo pa-5 mr-6 rounded">
					<v-btn
						v-if="newLogoUploading"
						depressed
						color="teal lighten-5"
						fab
						absolute
						top
						right
						style="top: -10px; right: -10px"
						x-small
						class="px-0"
						@click="clearLogoData"
					>
						<v-icon class="px-0 mx-0" x-small>fas fa-trash</v-icon>
					</v-btn>
					<v-img contain width="100" height="100" :lazy-src="logo" :src="logo"></v-img>
				</v-avatar>
				<div class="d-flex flex-column justify-center">
					<v-btn color="teal" dark @click="chooseLogo">yeni logo yükle</v-btn>
					<v-file-input ref="logoFile" v-model="logoFile" accept="image/*" @change="loadImage" class="d-none">
					</v-file-input>
					<p class="text-sm mt-5">Dosya formatı JPG, GIF ya da PNG. Maks dosya boyutu 800K</p>
				</div>
			</div>

			<v-row>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Tema Renk Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-select
										hide-details="auto"
										color="teal"
										label="Tema Seçimi"
										outlined
										dense
										:items="templates"
										item-text="tmpName"
										item-value="tmpId"
										v-model="cmsTheme.templateId"
									></v-select>
								</v-col>
								<v-col cols="12">
									<v-select
										hide-details="auto"
										color="teal"
										label="Renk Seçimi"
										outlined
										dense
										:items="templateColors"
										item-text="clrName"
										item-value="clrId"
										v-model="cmsTheme.colorId"
									>
										<template v-slot:item="{ item, attrs, on }">
											<v-list-item v-on="on" v-bind="attrs" :color="item.clrKey" :active-class="`grey lighten-2`">
												<v-list-item-icon class="mr-5">
													<v-avatar size="20" :color="item.clrKey"></v-avatar>
												</v-list-item-icon>
												<v-list-item-content>
													{{ item.clrName }}
												</v-list-item-content>
											</v-list-item>
										</template>
										<template v-slot:selection="{ item }">
											<div><v-avatar class="mr-2" size="20" :color="item.clrKey"></v-avatar>{{ item.clrName }}</div>
										</template>
									</v-select>
								</v-col>

								<v-col cols="12">
									<v-text-field label="Yazı Rengi" dense v-model="cmsTheme.textColor" hide-details outlined readonly>
										<template v-slot:append>
											<v-menu
												v-model="themeColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchTextStyle()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.textColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Tema Buton Renk Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Button Rengi"
										dense
										v-model="cmsTheme.buttonBackColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeButtonColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchButtonColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.buttonBackColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Button Yazı Rengi"
										dense
										v-model="cmsTheme.buttonTextColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeButtonTextColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchButtonTextColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.buttonTextColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Tema Header Renk Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Header Rengi"
										dense
										v-model="cmsTheme.headerBackColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeHeaderColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchHeaderColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.headerBackColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Header Yazı Rengi"
										dense
										v-model="cmsTheme.headerTextColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeHeaderTextColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchHeaderTextColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.headerTextColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Tema Footer Renk Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Footer Rengi"
										dense
										v-model="cmsTheme.footerBackColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeFooterColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchFooterColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.footerBackColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Footer Yazı Rengi"
										dense
										v-model="cmsTheme.footerTextColor"
										hide-details
										outlined
										readonly
									>
										<template v-slot:append>
											<v-menu
												v-model="themeFooterTextColorMenu"
												top
												nudge-bottom="105"
												nudge-left="16"
												:close-on-content-click="false"
											>
												<template v-slot:activator="{ on }">
													<div class="elevation-1" :style="swatchFooterTextColor()" v-on="on" />
												</template>
												<v-card>
													<v-card-text class="pa-0">
														<v-color-picker flat v-model="cmsTheme.footerTextColor" />
													</v-card-text>
												</v-card>
											</v-menu>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Başlık & Favicon</v-card-title>
						<v-card-text>
							<v-row>
								<v-col class="text-right">
									<v-text-field
										label="Sayfa Başlığı (Page Title)"
										outlined
										hide-details="auto"
										flat
										dense
										color="teal"
										v-model="cmsTheme.pageTitle"
									></v-text-field>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-file-input
										label="Favicon"
										hide-details
										dense
										color="teal"
										outlined
										prepend-icon=""
										prepend-inner-icon="fa-paperclip fa-xs"
										@change="faviconChanged"
										@click:clear="faviconClear"
									>
									</v-file-input>
									<div class="d-flex justify-end">
										<v-btn
											height="30"
											depressed
											link
											:href="cmsTheme.favicon.fileName"
											target="_blank"
											class="font-weight-bold text-capitalize"
											v-if="cmsTheme.favicon && !cmsTheme.favicon.fileData"
											>favicon İndir</v-btn
										>
										<v-btn
											height="30"
											depressed
											link
											@click="faviconClear"
											target="_blank"
											color="red"
											dark
											class="font-weight-bold text-capitalize"
											v-if="cmsTheme.favicon && !cmsTheme.favicon.fileData"
											>favicon Sil</v-btn
										>
									</div>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Meta Açıklaması</v-card-title>
						<v-card-text>
							<v-row>
								<v-col class="text-right">
									<v-text-field
										label="Sayfa Meta Açıklaması (Meta Description)"
										outlined
										hide-details="auto"
										flat
										dense
										color="teal"
										v-model="cmsTheme.metaDescription"
									></v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Anasayfa Wizard Ayarları </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-select
										@input="selectedWizardSize($event)"
										v-model="cmsTheme.wizardBarSize"
										:items="wizardSizes"
										hide-details="auto"
										label="Wizard Boyutu"
										dense
										outlined
									></v-select>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card>
						<v-card-title> Hata Sayfası Ayarları</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="cmsTheme.error404Setting.text"
										hide-details="auto"
										label="Hata Mesajı"
										full-width
										rows="2"
										dense
										no-resize
										outlined
									></v-textarea>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-file-input
										label="Hata sayfası görseli"
										hide-details
										dense
										color="teal"
										outlined
										prepend-icon=""
										prepend-inner-icon="fa-paperclip fa-xs"
										@change="error404ImageChanged"
										@click:clear="error404ImageClear"
									>
									</v-file-input>
									<div class="d-flex justify-end">
										<v-btn
											height="30"
											depressed
											link
											:href="cmsTheme.error404Setting.file"
											target="_blank"
											class="font-weight-bold text-capitalize"
											v-if="cmsTheme.error404Setting.file && !cmsTheme.error404Setting.file.fileData"
											>Görseli göster</v-btn
										>
										<v-btn
											height="30"
											depressed
											link
											@click="error404ImageClear"
											target="_blank"
											color="red"
											dark
											class="font-weight-bold text-capitalize"
											v-if="cmsTheme.error404Setting.file && !cmsTheme.error404Setting.file.fileData"
											>Sil</v-btn
										>
									</div>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<banner-list
						ref="bannerRef"
						:cms-theme.sync="cmsTheme"
						:check-file-ext-fn="checkFileExtension"
						:get-file-fn="getFile"
						:read-as-data-fn="readAsDataURL"
					></banner-list>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card-title class="px-0">Slogan</v-card-title>
					<v-row dense>
						<v-col cols="12">
							<v-textarea
								hide-details="auto"
								outlined
								full-width
								rows="3"
								no-resize
								label="Slogan Giriniz.."
								color="teal"
								v-model="cmsTheme.slogan"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="4" xl="3">
					<v-card-title class="px-0">Sayfa Alt Bilgisi</v-card-title>
					<v-row dense>
						<v-col cols="12">
							<v-textarea
								hide-details="auto"
								outlined
								full-width
								rows="3"
								no-resize
								label="Sayfa Alt Bilgisi Giriniz.."
								color="teal"
								v-model="cmsTheme.footer"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" md="3">
					<v-card-title class="px-0">Sıkça Sorulan Sorular</v-card-title>
					<v-row dense>
						<v-col cols="12">
							<v-text-field
								label="Başlık Giriniz.."
								outlined
								hide-details="auto"
								flat
								dense
								color="teal"
								v-model="cmsTheme.faqHeader"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								hide-details="auto"
								outlined
								rows="5"
								no-resize
								label="Açıklama Giriniz.."
								color="teal"
								v-model="cmsTheme.faqDescription"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="3">
					<v-card-title class="px-0">Süreçler</v-card-title>
					<v-row dense>
						<v-col cols="12">
							<v-text-field
								label="Başlık Giriniz.."
								outlined
								hide-details="auto"
								flat
								dense
								color="teal"
								v-model="cmsTheme.processHeader"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								hide-details="auto"
								outlined
								rows="5"
								no-resize
								label="Açıklama Giriniz.."
								color="teal"
								v-model="cmsTheme.processDescription"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="6">
					<v-card-title class="px-0">İletişim Bilgileri</v-card-title>
					<v-row dense>
						<v-col cols="6">
							<v-select
								outlined
								label="Şehir seçiniz"
								item-text="citName"
								item-value="citId"
								:items="cities"
								:return-object="false"
								hide-details="auto"
								dense
								color="teal"
								v-model="cmsTheme.cityId"
							></v-select>
						</v-col>
						<v-col cols="5">
							<v-text-field-simplemask
								v-model="cmsTheme.phone"
								v-bind:label="'Telefon numarası giriniz'"
								v-bind:properties="{
									color: 'teal',
									inputmode: 'numeric',
									prefix: phonePrefix,
									placeholder: phonePlaceHolder,
									dense: true,
									outlined: true,
									hideDetails: 'auto',
									flat: true,
								}"
								v-bind:options="{
									inputMask: phoneMask,
									outputMask: '##########',
									empty: null,
									applyAfter: false,
									alphanumeric: false,
									lowerCase: false,
								}"
							/>
						</v-col>
						<v-col cols="1">
							<v-tooltip left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on" @click="customerService">
										<v-icon color="grey lighten-1">
											<template v-if="isCustomerService"> fa-square-check </template>
											<template v-else> fa-square </template>
										</v-icon>
									</v-btn>
								</template>
								<span>Numarayı çağrı merkezi formatında göster </span>
							</v-tooltip>
						</v-col>
						<v-col cols="6">
							<v-textarea
								outlined
								rows="5"
								hide-details="auto"
								label="Adres giriniz"
								v-model="cmsTheme.address"
							></v-textarea>
						</v-col>
						<v-col cols="6">
							<v-text-field
								color="teal"
								outlined
								flat
								dense
								v-model="cmsTheme.email"
								label="E-Posta adresi giriniz"
								hide-details="auto"
								:error-messages="emailErrors()"
							></v-text-field>

							<v-checkbox
								hide-details="auto"
								dense
								v-model="cmsTheme.showContactInfoHeader"
								label="İletişim Bilgilerini En Üstte Göster"
							>
							</v-checkbox>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn
				:loading="savePreloader"
				color="teal"
				class="text-capitalize mr-2"
				:dark="!saveButtonIsDisabled"
				@click="save"
				:disabled="saveButtonIsDisabled"
			>
				Değişiklikleri Kaydet
			</v-btn>
			<v-btn :loading="previewPreloader" color="primary lighten-1" class="text-capitalize" dark @click="preview"
				>Ön İzleme</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import { isBase64Image } from '@/common/utils';
import themeValidationMixin from '@/mixins/validations/backoffice/themeValidationMixin';
import { FETCH_SITE_SETTINGS, UPDATE_SITE_TEMPLATE } from '@/store/modules/company.module';
import { DISPOSE_MESSAGE, SET_MESSAGE_DIALOG } from '@/store/modules/messageDialog.module';
import { SET_COMPANY_PREVIEW, UPLOAD_PREVIEW_IMAGE } from '@/store/modules/preview.module';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

import BannerList from './components/banner.vue';

export default {
	name: 'Theme',
	components: {
		BannerList,
	},
	mixins: [validationMixin, themeValidationMixin],
	data() {
		return {
			wizardSizes: [
				{ value: 1, text: 'Küçük' },
				{ value: 2, text: 'Orta' },
				{ value: 3, text: 'Büyük' },
			],
			logoData: null,
			logoFile: null,
			savePreloader: false,
			previewPreloader: false,
			logoBlob: null,
			themeColorMenu: false,
			themeButtonColorMenu: false,
			themeButtonTextColorMenu: false,
			themeHeaderColorMenu: false,
			themeHeaderTextColorMenu: false,
			themeFooterColorMenu: false,
			themeFooterTextColorMenu: false,
			tmpLogo: null,
			cmsTheme: {
				templateId: null,
				colorId: null,
				textColor: null,
				buttonBackColor: null,
				buttonTextColor: null,
				headerBackColor: null,
				headerTextColor: null,
				companyLogo: null,
				faqHeader: null,
				faqDescription: null,
				processHeader: null,
				processDescription: null,
				slogan: null,
				footer: null,
				companyLogoPath: null,
				companyLogoByte: null,
				companyLogoContentType: null,
				cityId: null,
				address: null,
				banners: [],
				wizardBarSize: null,
				favicon: {
					fileData: null,
					fileName: null,
				},
				footerBackColor: null,
				footerTextColor: null,
				phone: null,
				email: null,
				showContactInfoHeader: false,
				pageTitle: null,
				error404Setting: {
					text: null,
					file: {
						fileData: null,
						fileName: null,
					},
				},
				metaDescription: null,
			},
			bannerRef: null,
			customerServiceChecked: false,
		};
	},

	computed: {
		...mapGetters(['settings', 'getSite']),
		initialized() {
			return this.cmsSiteSettings !== null;
		},
		logo() {
			return this.logoData ?? this.cmsTheme.companyLogoPath;
		},
		cities() {
			return this.settings.cityList;
		},
		templates() {
			return this.settings.templateList;
		},
		templateColors() {
			return this.settings.templateColorList;
		},
		saveButtonIsDisabled() {
			const bannerComponentHasError = this.bannerRef?.$v.$invalid;

			return this.$v.cmsTheme.$invalid || bannerComponentHasError;
		},
		isCustomerService() {
			return this.customerServiceChecked;
		},
		phoneMask() {
			if (this.isCustomerService) return '### # ###';

			return '(###)-###-##-##';
		},
		phonePlaceHolder() {
			if (this.isCustomerService) return '444 4 444';

			return '(555)-555-55-55';
		},
		phonePrefix() {
			if (this.isCustomerService) return null;

			return '+90';
		},
		newLogoUploading() {
			return isBase64Image(this.logoData);
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();

			this.bannerRef = this.$refs.bannerRef;

			if (this.getSite.phone !== null && this.getSite.phone !== '') {
				this.customerServiceChecked = this.getSite.phone.startsWith('444');
			}
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateSiteTemplate: UPDATE_SITE_TEMPLATE,
			setCompanyPreview: SET_COMPANY_PREVIEW,
			uploadPreviewImage: UPLOAD_PREVIEW_IMAGE,
		}),
		selectedWizardSize(e) {
			this.cmsTheme.wizardBarSize = e;
		},
		getFile(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				const byteReader = new FileReader();

				byteReader.onload = () => {
					const byteArray = new Uint8Array(byteReader.result);
					const byte = Array.from(byteArray); // Direkt olarak Array.from kullanarak byte dizisini oluşturuyoruz.

					reader.onload = (e) => {
						resolve({
							eventResult: e.target.result, // Base64 sonucu
							name: file.name,
							file: byte, // Byte dizisi
						});
					};
					reader.readAsDataURL(file);
				};

				byteReader.readAsArrayBuffer(file);
			});
		},
		async readAsDataURL(file) {
			const fileReader = new FileReader();
			return new Promise((resolve) => {
				if (file.type.indexOf('image') > -1) {
					this.showThumbnail = true;
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
				}
				fileReader.readAsDataURL(file);
			});
		},
		chooseLogo() {
			this.$refs.logoFile.$el.querySelector('input[type="file"]').click();
		},
		checkFileExtension(files, extensions) {
			return new Promise((resolve, reject) => {
				const hasIgnoredExt = Array.from(files).some((f) => !extensions.exec(f?.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		async loadImage() {
			if (this.logoFile) {
				// const reader = new FileReader();
				// reader.onload = (e) => {
				// 	this.logoData = e.target.result;
				// };
				// reader.readAsDataURL(this.logoFile);

				const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
				this.checkFileExtension([this.logoFile], allowedExtensions).then(async () => {
					if (this.logoFile) {
						const response = await this.getFile(this.logoFile);

						this.logoData = response.eventResult;
						this.cmsTheme.companyLogoByte = response.file;
						this.cmsTheme.companyLogo = response.name;
						// this.logoBlob = await this.readAsDataURL(this.logoFile);
					}
				});
			}
		},
		// async changeLogoFile(e) {
		// 	const data = e.target.files[0];

		// 	const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
		// 	this.checkFileExtension([data], allowedExtensions).then(async () => {
		// 		if (data) {
		// 			const response = await this.getFile(data);
		// 			this.cmsTheme.companyLogoByte = response.file;
		// 			this.cmsTheme.companyLogo = response.name;
		// 			this.logoBlob = await this.readAsDataURL(data);

		// 			if (this.logoBlob) {
		// 				this.tmpLogo = this.cmsTheme.companyLogoPath;
		// 				this.cmsTheme.companyLogoPath = this.logoBlob;
		// 			}
		// 		}
		// 	});
		// },
		clearLogoData() {
			this.logoData = null;
			this.$refs.logoFile.$el.querySelector('input[type="file"]').value = '';
			// this.logoBlob = null;
			// this.cmsTheme.companyLogoByte = null;
			// this.cmsTheme.companyLogoPath = this.tmpLogo;
			// this.cmsTheme.companyLogo = this.tmpLogo;
			// this.tmpLogo = null;
			// this.$refs.logoFile.value = null;
		},
		faviconChanged(e) {
			const image = e;
			if (image) {
				const allowedExtensions = /(\.ico)$/i;

				this.checkFileExtension([image], allowedExtensions).then(async () => {
					const response = await this.getFile(image);
					this.cmsTheme.favicon = {
						fileData: response.file,
						fileName: response.name,
					};
				});
			}
		},
		faviconClear() {
			this.cmsTheme.favicon = null;
		},
		error404ImageChanged(e) {
			const image = e;
			if (image) {
				const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;

				this.checkFileExtension([image], allowedExtensions).then(async () => {
					const response = await this.getFile(image);
					this.cmsTheme.error404Setting.file = {
						fileData: response.file,
						fileName: response.name,
					};
				});
			}
		},
		error404ImageClear() {
			this.cmsTheme.error404Setting.file = null;
		},
		swatchTextStyle() {
			return {
				backgroundColor: this.cmsTheme.textColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchButtonColor() {
			return {
				backgroundColor: this.cmsTheme.buttonBackColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColor1Menu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchButtonTextColor() {
			return {
				backgroundColor: this.cmsTheme.buttonTextColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchHeaderColor() {
			return {
				backgroundColor: this.cmsTheme.headerBackColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColor1Menu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchHeaderTextColor() {
			return {
				backgroundColor: this.cmsTheme.headerTextColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchFooterColor() {
			return {
				backgroundColor: this.cmsTheme.footerBackColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColor1Menu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		swatchFooterTextColor() {
			return {
				backgroundColor: this.cmsTheme.footerTextColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: this.themeColorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
		save() {
			this.savePreloader = true;
			this.updateSiteTemplate(this.cmsTheme)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
					this.clearLogoData();
				})
				.finally(() => {
					this.savePreloader = false;
					this.fetchSiteSettings();
				});
		},
		preview() {
			this.previewPreloader = true;
			const company = this.cmsTheme;

			this.setMessageDialog({
				messageType: 'info',
				subTitle: 'Ön izleme oluşturuluyor',
				text: 'Önizlemeniz oluşturulumaktadır. Lütfen bekleyiniz.',
				buttons: null,
			})
				.then(() => {
					const previewSite = { ...this.getSite };
					previewSite.address = company.address;

					previewSite.cityId = this.settings.cityList.find((city) => city.citId === company.cityId)?.citId;

					previewSite.faqHeader = company.faqHeader;
					previewSite.faqDescription = company.faqDescription;
					previewSite.processHeader = company.processHeader;
					previewSite.processDescription = company.processDescription;
					previewSite.footer = company.footer;

					previewSite.templateCode = this.settings.templateList.find(
						(template) => template.tmpId === company.templateId,
					)?.tmpCode;

					previewSite.templateColor = this.settings.templateColorList.find((color) => color.clrId === company.colorId);

					previewSite.templateTextColor = company.textColor;

					const logoUploadPromise = new Promise((resolve) => {
						if (company.companyLogoByte) {
							const payload = [this.logoData];

							this.uploadPreviewImage(payload).then((response) => {
								company.companyLogo = `${response[0]}` ?? null;
								resolve();
							});
						} else {
							resolve();
						}
					});

					const uploadPromise = new Promise((resolve) => {
						const newAddedBanners = company.banners.filter((x) => isBase64Image(x.cbnImage));

						// 	.filter(
						// 	(banner) => !banner.isDeleted && banner.bannerBlob && !banner.tmpUploaded,
						// );

						if (newAddedBanners.length > 0) {
							const payload = newAddedBanners.map((banner) => banner.cbnImage);

							this.uploadPreviewImage(payload).then((response) => {
								newAddedBanners.forEach((item, index) => {
									const banner = item;
									banner.cbnImage = response[index];
									banner.tmpUploaded = true;
								});
								resolve();
							});
						} else resolve();
					});

					Promise.all([uploadPromise, logoUploadPromise]).then(() => {
						previewSite.companyLogo = `${company.companyLogo}`;

						previewSite.banners = company.banners
							// .filter((banner) => !banner.isDeleted)
							.map((banner) => ({
								file: banner.tmpUploaded ? `${banner.cbnImage}` : banner.cbnImage,

								color: banner.cbnTextColor,
								title: banner.cbnTitle,
								subTitle: banner.cbnSubTitle,
							}));

						this.setCompanyPreview(previewSite).then(() => {
							setTimeout(() => {
								this.disposeMessageDialog().then(() => {
									setTimeout(() => {
										this.previewPreloader = false;
										window.open('/?preview', '_blank');
									}, 500);
								});
							}, 2000);
						});
					});
				})
				.finally(() => {
					this.previewPreloader = false;
				});
		},
		customerService() {
			this.cmsTheme.phone = null;
			this.customerServiceChecked = !this.customerServiceChecked;
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsTheme = {
					templateId: company.cmpTmpId,
					colorId: company.cmpClrId,
					textColor: company.cmpTxtColor,
					buttonBackColor: company.cmpButtonBackColor,
					buttonTextColor: company.cmpButtonTextColor,
					headerBackColor: company.cmpHeaderBackColor,
					headerTextColor: company.cmpHeaderTextColor,
					companyLogo: company.cmpCompanyLogo,
					faqHeader: company.cmpFaqHeader,
					faqDescription: company.cmpFaqDescription,
					processHeader: company.cmpProcessHeader,
					processDescription: company.cmpProcessDescription,
					slogan: company.cmpSlogan,
					footer: company.cmpFooter,
					companyLogoPath: company.companyLogoPath,
					companyLogoByte: company.companyLogoByte,
					companyLogoContentType: company.companyLogoContentType,
					cityId: company.cmpCitId,
					address: company.cmpAddress,
					banners: company.banners,
					companyId: company.cmpId,
					wizardBarSize: company.cmpWizardBarSize,
					favicon:
						company.faviconPath === null
							? null
							: {
									fileName: company.faviconPath,
							  },
					footerBackColor: company.cmpFooterBackColor,
					footerTextColor: company.cmpFooterTextColor,
					phone: company.cmpPhone,
					email: company.cmpEmail,
					showContactInfoHeader: company.cmpShowContactInfoHeader,
					pageTitle: company.cmpPageTitle,
					error404Setting: {
						text: company.cmp404Text,
						file: {
							fileName: company.error404ImagePath,
						},
					},
					metaDescription: company.cmpMetaDescription,
				};
			},
			deep: true,
		},
	},
};
</script>

<style scoped>
.logo {
	border: 1px solid teal;
	position: relative;
	overflow: initial;
}
</style>
