<template>
	<v-form v-model="formValid" ref="form">
		<div>
			<v-dialog v-model="dialog" fullscreen hide-overlay>
				<v-card class="d-flex flex-column">
					<v-card-title class="text-h5" :class="titleClass">
						<span>Banner Ekle</span>
						<v-spacer></v-spacer>
						<v-btn icon @click="dialog = false">
							<v-icon>fa-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-card-text class="pa-5 flex-grow-1">
						<v-row dense>
							<v-col>
								<v-file-input
									v-model="desktopImageFile"
									outlined
									flat
									dense
									hide-details
									label="Masaüstü İçin Resim Yükle"
									accept="image/*"
									prepend-icon=""
									@change="loadImage('desktop')"
									@click:clear="clearImage('desktop')"
									required
									:rules="[rules.required]"
									ref="desktopFileInput"
								>
									<template v-slot:prepend-inner>
										<div style="display: flex; align-items: center; justify-content: center; width: 24px; height: 22px">
											<v-icon size="14">fas fa-desktop</v-icon>
										</div>
									</template>
								</v-file-input>

								<!-- Kırpma ve Önizleme  -->
								<div v-if="desktopImage && !desktopCropComplete" style="width: 100%; height: 50%">
									<cropper
										class="cropper"
										:src="desktopImage"
										:stencil-props="{ aspectRatio: 4 / 1.2 }"
										ref="desktopCropper"
										auto-zoom
										center
									></cropper>
									<v-btn @click="cropImage('desktop')" depressed link dark color="primary lighten-1"
										>Kırp ve Kaydet</v-btn
									>
								</div>

								<!-- Kırpılan Masaüstü Resmi Önizleme -->
								<div v-if="form.desktopImagePreview" class="my-4">
									<v-img :src="form.desktopImagePreview" aspect-ratio="4 / 1.2" height="200" contain></v-img>
									<v-btn
										@click="resetCrop('desktop')"
										depressed
										link
										dark
										color="primary lighten-1"
										class="text-capitalize mr-2"
										>Yeniden Düzenle</v-btn
									>
									<v-btn
										depressed
										link
										dark
										@click="clearImage('desktop')"
										color="red"
										class="font-weight-bold text-capitalize"
										>Sil</v-btn
									>
								</div>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col>
								<!-- Mobil Resim Yükleme ve Kırpma -->
								<v-file-input
									v-model="mobileImageFile"
									outlined
									flat
									dense
									hide-details
									label="Mobil İçin Resim Yükle"
									accept="image/*"
									prepend-icon=""
									@change="loadImage('mobile')"
									@click:clear="clearImage('mobile')"
									required
									:rules="[rules.required]"
									ref="mobileFileInput"
								>
									<template v-slot:prepend-inner>
										<div style="display: flex; align-items: center; justify-content: center; width: 24px; height: 22px">
											<v-icon size="14">fas fa-mobile-alt</v-icon>
										</div>
									</template>
								</v-file-input>

								<!-- Kırpma ve Önizleme  -->
								<div v-if="mobileImage && !mobileCropComplete" style="width: 100%; height: 50%">
									<cropper
										class="cropper"
										:src="mobileImage"
										:stencil-props="{ aspectRatio: 1 / 1 }"
										ref="mobileCropper"
										auto-zoom
										center
									></cropper>
									<v-btn @click="cropImage('mobile')" depressed link dark color="primary lighten-1"
										>Kırp ve Kaydet</v-btn
									>
								</div>

								<div v-if="form.mobileImagePreview" class="my-4">
									<v-img :src="form.mobileImagePreview" aspect-ratio="1/1" height="200" contain></v-img>
									<v-btn
										@click="resetCrop('mobile')"
										depressed
										link
										dark
										color="primary lighten-1"
										class="text-capitalize mr-2"
										>Yeniden Düzenle</v-btn
									>
									<v-btn
										@click="clearImage('mobile')"
										depressed
										link
										dark
										color="red"
										class="font-weight-bold text-capitalize"
										>Sil</v-btn
									>
								</div>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col>
								<!-- Başlık -->
								<v-text-field v-model="form.title" outlined flat dense hide-details label="Başlık">
									<template v-slot:prepend-inner>
										<div style="display: flex; align-items: center; justify-content: center; width: 24px; height: 22px">
											<v-icon size="14">fas fa-heading</v-icon>
										</div>
									</template>
								</v-text-field>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col>
								<!-- Alt Başlık -->
								<v-text-field v-model="form.subtitle" outlined flat dense hide-details label="Alt Başlık">
									<template v-slot:prepend-inner>
										<div style="display: flex; align-items: center; justify-content: center; width: 24px; height: 22px">
											<v-icon size="14">fas fa-font</v-icon>
										</div>
									</template>
								</v-text-field>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col>
								<!-- Link -->
								<v-text-field
									v-model="form.link"
									outlined
									flat
									dense
									hide-details
									label="Link"
									type="url"
									:rules="[urlValidation]"
								>
									<template v-slot:prepend-inner>
										<div style="display: flex; align-items: center; justify-content: center; width: 24px; height: 22px">
											<v-icon size="14">fas fa-link</v-icon>
										</div>
									</template>
								</v-text-field>
							</v-col>
						</v-row>

						<v-row dense>
							<v-col>
								<!-- Renk Seçici -->
								<v-color-picker
									v-model="form.color"
									label="Renk Seç"
									flat
									dense
									hide-details
									class="mt-4"
								></v-color-picker>
							</v-col>
						</v-row>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="cancel">Vazgeç</v-btn>
						<v-btn color="primary" :disabled="!formValid" text @click="handleAccept">Tamam</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						depressed
						class="text-capitalize"
						v-bind="attrs"
						v-on="on"
						@click="dialog = true"
						:disabled="isDisabled"
					>
						<v-icon left x-small>fas fa-plus</v-icon>
						Yeni Banner Ekle
					</v-btn>
				</template>
				<span>Maksimum 5 adet banner ekleyebilirsiniz.</span>
			</v-tooltip>
		</div>
	</v-form>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
	name: 'NewBannerDialog',
	components: {
		Cropper,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		bannerData: {
			type: Object,
			default: () => null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: this.value,
			valid: false,
			desktopImageFile: null,
			mobileImageFile: null,
			desktopImage: null,
			mobileImage: null,
			desktopCropComplete: false,
			mobileCropComplete: false,
			form: {
				desktopImagePreview: null,
				mobileImagePreview: null,
				title: '',
				subtitle: '',
				link: '',
				color: '#000000',
			},
			formValid: false,
			rules: {
				required: (value) => !!value || 'Görsel seçiniz !!!',
			},
			urlValidation: (value) => {
				if (!value) return true;
				const pattern = new RegExp(
					'^(https?:\\/\\/)?' +
						'((([a-zA-Z0-9$-_@.&+!*"(),]|[a-zA-Z0-9]+))+)' +
						'(\\.[a-zA-Z]{2,5})' +
						'(:[0-9]{1,5})?' +
						'(\\/.*)?$',
				);
				return pattern.test(value) || 'Geçerli bir URL giriniz';
			},
		};
	},
	computed: {
		titleClass() {
			return this.$vuetify.theme.dark ? 'text-white' : 'text-black';
		},
		isDisabled() {
			return this.disabled;
		},
	},
	methods: {
		loadImage(type) {
			const file = type === 'desktop' ? this.desktopImageFile : this.mobileImageFile;
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					if (type === 'desktop') {
						this.desktopImage = e.target.result;
					} else {
						this.mobileImage = e.target.result;
					}
				};
				reader.readAsDataURL(file);
			}
		},
		clearImage(type) {
			if (type === 'desktop') {
				this.desktopImage = null;
				this.form.desktopImagePreview = null;
				this.desktopCropComplete = false;
				this.desktopImageFile = null;
			} else {
				this.mobileImage = null;
				this.form.mobileImagePreview = null;
				this.mobileCropComplete = false;
				this.mobileImageFile = null;
			}
		},
		cropImage(type) {
			const cropperRef = type === 'desktop' ? this.$refs.desktopCropper : this.$refs.mobileCropper;
			const croppedCanvas = cropperRef.getResult().canvas;

			if (croppedCanvas) {
				const croppedImage = croppedCanvas.toDataURL('image/jpeg', 0.8);

				if (type === 'desktop') {
					this.form.desktopImagePreview = croppedImage;
					this.desktopCropComplete = true;
				} else {
					this.form.mobileImagePreview = croppedImage;
					this.mobileCropComplete = true;
				}
			}
		},
		resetCrop(type) {
			if (type === 'desktop') {
				this.desktopCropComplete = false;
				this.form.desktopImagePreview = null;
			} else {
				this.mobileCropComplete = false;
				this.form.mobileImagePreview = null;
			}
		},
		handleAccept() {
			const imageData = {
				cbnImage: this.form.desktopImagePreview,
				cbnMobileImage: this.form.mobileImagePreview,
				cbnTitle: this.form.title,
				cbnSubTitle: this.form.subtitle,
				cbnTextColor: this.form.color,
				cbnLink: this.form.link,
			};

			if (imageData.cbnImage !== null && imageData.cbnMobileImage !== null) {
				this.$emit('submit-banner', imageData);

				this.dialog = false;
			} else {
				// eslint-disable-next-line no-lonely-if
				if (imageData.cbnImage === null) {
					this.$refs.desktopFileInput.$el.querySelector('input').focus();
				} else {
					// eslint-disable-next-line no-lonely-if
					if (imageData.cbnMobileImage === null) {
						this.$refs.mobileFileInput.$el.querySelector('input').focus();
					}
				}
			}
		},
		resetForm() {
			this.desktopImageFile = null;
			this.mobileImageFile = null;
			this.desktopImage = null;
			this.mobileImage = null;
			this.desktopCropComplete = false;
			this.mobileCropComplete = false;
			this.form.desktopImagePreview = null;
			this.form.mobileImagePreview = null;
			this.form.title = '';
			this.form.subtitle = '';
			this.form.link = '';
			this.form.color = '#000000';
		},
		cancel() {
			this.dialog = false;
		},
	},
	watch: {
		value(val) {
			this.dialog = val;
		},
		dialog: {
			handler(val) {
				this.$emit('input', val);

				if (!val) this.resetForm();
			},
		},
		bannerData: {
			handler(data) {
				if (data) {
					this.form.desktopImagePreview = data.cbnImage;
					this.form.mobileImagePreview = data.cbnMobileImage;
					this.form.title = data.cbnTitle;
					this.form.subtitle = data.cbnSubTitle;
					this.form.link = data.cbnLink;
					this.form.color = data.cbnTextColor;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.cropper {
	max-width: 100%;
	min-width: 100%;
	min-height: 100;
	height: auto;
	margin-bottom: 20px;
	border: 1px solid #ddd;
}
</style>
